var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-search-wrap"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "search-select v-input--density-compact",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "items": _vm.searchKeys,
      "item-text": "text",
      "item-value": "value"
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--width-fixed v-input--density-compact",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "placeholder": "검색어를 입력하세요."
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-primary', {
    on: {
      "click": function ($event) {
        return _vm.search();
      }
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('table', {
    staticClass: "board-list"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.questions, function (question, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.checkStatus(question);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__tit"
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(question.subject))])])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(question.name) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(question.createdAt).format("YYYY-MM-DD")) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [question.reply ? _c('span', {
      staticClass: "secondary--text"
    }, [_vm._v("답변완료")]) : _c('span', {
      staticClass: "board-list__txt"
    }, [_vm._v("답변대기")])])]);
  }), 0)]), _c('div', {
    staticClass: "btn-wrap btn-wrap--sm d-flex justify-end"
  }, [_c('v-btn', {
    attrs: {
      "href": "/center/inquiry/input",
      "tile": "",
      "color": "primary"
    }
  }, [_vm._v("문의하기")])], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('inquiry-dialog', _vm._b({
    on: {
      "close": function () {
        return _vm.dialog = false;
      },
      "checkPassword": _vm.checkPassword
    }
  }, 'inquiry-dialog', {
    value: _vm.selected,
    dialog: _vm.dialog,
    type: _vm.type
  }, false))], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "grey lighten-3 d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "calc(760/1200*100%)"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "calc(120/1200*100%)"
    }
  }, [_vm._v("작성자")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "calc(200/1200*100%)"
    }
  }, [_vm._v("작성일")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "calc(120/1200*100%)"
    }
  }, [_vm._v("상태")])])]);

}]

export { render, staticRenderFns }