var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.question ? _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.question.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("작성자 : 관리자")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(" 작성일: " + _vm._s(_vm.$dayjs(_vm.question.createdAt).format("YYYY-MM-DD HH:mm:ss")))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 상태 : "), _vm.question.reply ? _c('span', [_vm._v("답변완료")]) : _c('span', [_vm._v("답변대기")])])], 1)], 1)]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.question.content))])])]), _c('tr', [_c('td', {
    staticClass: "grey lighten-4",
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("└")]), _c('v-col', [_vm._v(_vm._s(_vm.question.reply))])], 1)], 1)])]), _c('div', {
    staticClass: "mt-20px mt-md-30px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "tile": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v(_vm._s(_vm.$t("common.list")))])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "tile": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.open('remove');
      }
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("삭제")])])], 1), !_vm.question.reply ? _c('v-col', {
    attrs: {
      "cols": "auto"
    },
    on: {
      "click": function ($event) {
        return _vm.open('edit');
      }
    }
  }, [_c('v-btn', {
    attrs: {
      "tile": "",
      "color": "grey"
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("수정")])])], 1) : _vm._e()], 1)], 1), _c('inquiry-dialog', _vm._b({
    on: {
      "close": function () {
        return _vm.dialog = false;
      },
      "checkPassword": _vm.checkPassword
    }
  }, 'inquiry-dialog', {
    value: _vm.question,
    dialog: _vm.dialog,
    type: _vm.type
  }, false))], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }