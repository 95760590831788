<template>
    <v-dialog v-model="dialog" width="588">
        <v-card tile flat class="py-40px py-md-60px py-lg-80px px-lg-60px">
            <u-txt-default class="text-center grey--text text--darken-4 font-weight-bold">
                게시글 확인을 위한 비밀번호를 입력해주세요.
            </u-txt-default>
            <div class="py-16px px-20px">
                <v-text-field v-model="password" type="password" outlined placeholder="비밀번호를 입력하세요."></v-text-field>
            </div>
            <v-divider />
            <div class="btn-wrap">
                <v-row class="row--xs" justify="center">
                    <v-col cols="auto">
                        <v-btn x-large tile outlined class="w-160px" color="grey lighten-3" @click="close">
                            <span class="grey--text">취소</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn x-large tile class="w-160px white--text" color="black" @click="checkPassword">등록</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    components:{
        UTxtDefault
    },
    props:{
        value: { type: Object, default: ()=> ({})},
        dialog: { type: Boolean, default: false },
        type: { type: String, default: "" }
    },
    data(){
        return{
            // question: this.$props.value,
            password: "",
        }
    },
    methods:{
        close(){
            // this.dialog = false;
            this.$emit("close");
        },
        async checkPassword(){
            try{
                let { success } = await api.v1.center.questions.checkPassword({
                    _id: this.value._id,
                    password: CryptoAES.encrypt(this.password)
                })

                if(success) this.$emit("checkPassword", { type: this.type, success });

            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    }
}
</script>

<style>

</style>