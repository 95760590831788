<template>
    <div v-if="question" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ question.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">작성자 : 관리자</v-col>
                        <v-col cols="auto">
                            <span> 작성일: {{ $dayjs(question.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
                        </v-col>
                        <v-col cols="auto">
                            상태 :
                            <span v-if="question.reply">답변완료</span>
                            <span v-else>답변대기</span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <div>{{ question.content }}</div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="grey lighten-4">
                    <v-row class="row--xxs">
                        <v-col cols="auto">└</v-col>
                        <v-col>{{ question.reply }}</v-col>
                    </v-row>
                </td>
            </tr>
        </table>

        <!-- <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table> -->

        <div class="mt-20px mt-md-30px">
            <v-row class="row--xxs" justify="end">
                <v-col cols="auto">
                    <v-btn @click="$router.go(-1)" outlined tile color="grey lighten-3">
                        <span class="grey--text">{{ $t("common.list") }}</span>
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn outlined tile color="grey lighten-3" @click="open('remove')">
                        <span class="grey--text">삭제</span>
                    </v-btn>
                </v-col>
                <v-col v-if="!question.reply" cols="auto" @click="open('edit')">
                    <v-btn tile color="grey">
                        <span class="white--text">수정</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <inquiry-dialog v-bind="{ value: question, dialog, type }" @close="()=> dialog = false" @checkPassword="checkPassword"/>
    </div>
</template>

<script>
import api from "@/api";
import InquiryDialog from "@/components/client/center/inquiries/inquiry-dialog.vue"

import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    components: {
        UTxtDefault,
        InquiryDialog
    },
    data() {
        return {
            question: null,
            dialog: false,
            type: ""
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { question } = await api.v1.center.questions.get({ _id: this.$route.params._inquiry });

                this.question = question;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        open(value){
            this.dialog = true;
            this.type = value;
        },
        async remove(){
            try {
                await api.v1.center.questions.delete(this.question);

                alert("삭제되었습니다.");
                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        edit(){
            this.$router.push(`./input/${this.question._id}`);
        },
        checkPassword(value){
            let { type, success } = value;
            if( success && type === "remove"){
                this.remove();
                return;
            }

            if( success && type === "edit"){
                this.edit();
                return;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.board-view > tr > th {
    padding: 10px;
}
@media (min-width: 1024px) {
    .board-view > tr > th {
        padding: 16px 20px;
    }
}
</style>
