var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "588"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticClass: "py-40px py-md-60px py-lg-80px px-lg-60px",
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('u-txt-default', {
    staticClass: "text-center grey--text text--darken-4 font-weight-bold"
  }, [_vm._v(" 게시글 확인을 위한 비밀번호를 입력해주세요. ")]), _c('div', {
    staticClass: "py-16px px-20px"
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "outlined": "",
      "placeholder": "비밀번호를 입력하세요."
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('v-divider'), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-160px",
    attrs: {
      "x-large": "",
      "tile": "",
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("취소")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-160px white--text",
    attrs: {
      "x-large": "",
      "tile": "",
      "color": "black"
    },
    on: {
      "click": _vm.checkPassword
    }
  }, [_vm._v("등록")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }