<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="고객센터" tabActive="문의 게시판" bg="/images/center/visual.jpg">
                <template #title>고객센터</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>문의 게시판</template>
            <inquiry-list v-if="!$route.params._inquiry" />
            <inquiry-view v-else />
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import InquiryList from "@/components/client/center/inquiries/inquiry-list.vue";
import InquiryView from "@/components/client/center/inquiries/inquiry-view.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        InquiryList,
        InquiryView,
    },
};
</script>
