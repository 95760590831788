<template>
    <div class="board-container">
        <div class="board-search-wrap">
            <v-row justify="end" class="row--xxs">
                <v-col cols="12" md="auto">
                    <v-select hide-details class="search-select v-input--density-compact" outlined :items="searchKeys" item-text="text" item-value="value" v-model="filter.searchKey" />
                </v-col>
                <v-col md="auto">
                    <v-text-field hide-details class="v-input--width-fixed v-input--density-compact" outlined placeholder="검색어를 입력하세요." v-model="filter.searchValue"/>
                </v-col>
                <v-col cols="auto">
                    <btn-primary @click="search()">검색</btn-primary>
                </v-col>
            </v-row>
        </div>

        <table class="board-list">
            <thead class="grey lighten-3 d-none d-lg-table-header">
                <tr>
                    <!-- <th style="width:10%;" class="board-list__txt d-none d-lg-table-cell">{{$t('common.number')}}</th> -->
                    <th style="width:calc(760/1200*100%);" class="board-list__txt">제목</th>
                    <th style="width:calc(120/1200*100%);" class="board-list__txt">작성자</th>
                    <th style="width:calc(200/1200*100%);" class="board-list__txt">작성일</th>
                    <th style="width:calc(120/1200*100%);" class="board-list__txt">상태</th>
                </tr>
            </thead>
            <tbody>                
                <tr v-for="(question, index) in questions" :key="index" @click="checkStatus(question)">
                    <td class="board-list__tit">
                        <div class="ellip">
                            <span>{{ question.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ question.name }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ $dayjs(question.createdAt).format("YYYY-MM-DD")}}
                    </td>
                    <td align="center" class="board-list__txt">
                        <span v-if="question.reply" class="secondary--text">답변완료</span>
                        <span v-else class="board-list__txt">답변대기</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="btn-wrap btn-wrap--sm d-flex justify-end">
            <v-btn href="/center/inquiry/input" tile color="primary">문의하기</v-btn>
        </div>
        <div class="pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" />
        </div>
        
        <inquiry-dialog v-bind="{ value: selected, dialog, type }" @close="()=> dialog = false" @checkPassword="checkPassword"/>
    </div>
</template>

<script>
import api from "@/api";

import InquiryDialog from "@/components/client/center/inquiries/inquiry-dialog.vue"
import { mdiAlertCircleOutline } from "@mdi/js";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

export default {
    components: {
        BtnPrimary,
        InquiryDialog
    },
    props: {
        value: { type: String, default: null },
    },
    data() {
        return {
            mdiAlertCircleOutline,

            dialog: false,
            filter: {
                searchKey: "subject",
                searchValue: null,
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,

            questions: [],
            selected: {},
            type: "",

            searchKeys: [
                { text: "제목", value: "subject" },
                { text: "작성자", value: "name" },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search(routable = true) {
            if(routable){
                this.$router.push({
                    query: Object.assign({}, this.filter, { page: this.page }),
                });
            }

            var { summary, questions } = await api.v1.center.questions.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.questions = questions;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        checkStatus(item){
            if(item.status === "private") {
                this.selected = item;
                this.type = "view";
                this.dialog = true;
                return;
            };

            this.view(item._id);
        },
        checkPassword(value){
            if(value.success) this.view(this.selected._id);
        },
        view(_id){
            this.$router.push(`./inquiry/${_id}`);
        }
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
    .d-lg-table-header {
        display: table-header-group !important;
    }
}
</style>